<template>
    <div style="background-color:#ffffff;">
        <van-nav-bar title="用户流水" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="water_title">
            <div>ID</div>
            <div>昵称</div>
            <div>总流水</div>
            <div>详情</div>
        </div>
        <div class="list">
            <div class="noData" v-if="list.length == 0">
                <img src="img/noData.png" alt="">
            </div>
            <div v-for="(item, index) in list" :key="index" class="list_item">
                <div>{{ item.u_id }}</div>
                <div>{{ item.nickname }}</div>
                <div>{{ item.total_price }}</div>
                <div style="display:flex;align-items:center;justify-content:center;">
                    <van-button type="info" size="small"
                        @click="toUserInfo(item.receive_user_id, item.nickname, item.u_id)">流水详情</van-button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { UserWater } from "@/api/api";
export default {
    data() {
        return {
            type: null,
            item: null,
            union_id: null,
            list: []

        };
    },
    created() {
        this.type = this.$route.query.type
        this.item = this.$route.query.index
        this.union_id = this.$route.query.union_id
        this.getUserWater()
    },
    methods: {
        getUserWater() {
            let param = {
                type: this.type,
                union_id: this.union_id,
                item: this.item
            }

            UserWater(param).then(res => {
                console.log(res)
                this.list = res.data

            })
        },
        toUserInfo(id, name, u_id) {
            this.$router.push({ path: '/userWaterInfo?item=' + this.item + '&type=' + this.type + '&union_id=' + this.union_id + '&receive_user_id=' + id + '&u_id=' + u_id + '&name=' + name })
        },
        onClickLeft() {
            this.$router.back()
        }
    }
};
</script>
<style scoped>
.water_title {
    max-width: 100%;
    padding: 0 30px 0 30px;
    display: flex;
    height: 80px;
    line-height: 80px;
    background-color: #f2f2f2;
    font-size: 26px;
}

.water_title div {
    flex: 1;
    text-align: center;
}

.list {
    font-size: 24px;
}

.list_item {
    max-width: 100%;
    padding: 0 30px 0 30px;
    display: flex;
    height: 80px;
    line-height: 80px;
    background-color: #ffffff;
    border-bottom: 1px solid #f2f2f2;
}

.list_item div {
    flex: 1;
    text-align: center;
}
</style>>

</style>